@import "../../../assets/styles/button";
@import "../../../assets/styles/text";

.device {
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: SVN-Gotham;

  &-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 4rem;
    height: 100%;
  }

  .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 6rem;
    .title {
      font-weight: 700;
      font-size: 2.8rem;
      line-height: 3.6rem;
    }
    .search-style {
      width: 40rem;
      margin-right: 1.5rem;
    }
  }
  &__nav-header {
    display: flex;
    @include btn-font-style(500);
    color: var(--primary-gray-6);
    align-items: center;
    cursor: pointer;
    .tab {
      margin-right: 2rem;
      padding: 0.9rem 1.5rem;
      display: flex;
      border-radius: 100rem;
      span {
        display: flex;
        align-items: center;
        img {
          margin-right: 0.9rem;
          filter: brightness(0) invert(0.4);
        }
      }
      .total {
        height: 2.4rem;
        padding: 0 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.8rem;
        font-size: 1.4rem;
        line-height: 2.2rem;
        background: var(--primary-white);
        border-radius: 2rem;
        color: var(--primary-gray-6);
      }
      &-hover {
        &:hover {
          background: rgba(255, 255, 255, 0.223);
          @extend .btn--shadow;
        }
      }
      &-active {
        color: var(--primary-white);
        background: var(--primary-green);
        box-sizing: border-box;
        box-shadow: 0.4rem 0 0.8rem rgba(65, 173, 73, 0.2);
        .total {
          background: var(--secondary-green);
          color: var(--primary-white);
          font-weight: bold;
        }
        img {
          filter: brightness(0) invert(1) !important;
        }
      }
    }
  }
  .list {
    min-height: 10rem;
    width: 100%;
    overflow: auto;
    display: flex;
    height: 100%;
    flex-direction: column;

    &-header {
      @include btn-font-style(700);
      color: var(--primary-gray-2);
      background: var(--primary-gray);
      width: 100%;
      position: sticky;
      top: 0;
      z-index: 1;
      display: flex;
      padding: 0rem 3.2rem 1.6rem;
    }
    .ic {
      width: 7%;
    }
    .id {
      width: 20%;
    }
    .type {
      width: 20%;
      display: flex;
      align-items: center;
      img {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50%;
        margin-right: 1rem;
      }
    }
    .area {
      width: 22%;
      display: flex;
      flex-direction: column;
      .code {
        font-weight: 450;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: var(--primary-gray-6);
      }
    }
    .position {
      width: 16%;
    }
    .status {
      width: 13%;
    }
  }
}

.popover-menu-edit {
  button {
    display: flex;
    align-items: center;
    width: 15rem;
  }
}
