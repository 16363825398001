@import "../../../assets/styles/button";

.account {
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: SVN-Gotham;

  &-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 4rem;
    height: 100%;
  }

  .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 6rem;
    .search-style {
      width: 40rem;
      margin-right: 1.5rem;
    }
  }

  &__nav-header {
    display: flex;
    @include btn-font-style(500);
    color: var(--primary-green);
    align-items: center;
    cursor: pointer;
    .tab {
      margin-right: 2rem;
      padding: 0.9rem 1.5rem;
      display: flex;
      border-radius: 100rem;
      span {
        height: 2.4rem;
        padding: 0 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.8rem;
        font-size: 1.4rem;
        line-height: 2.2rem;
        background: var(--primary-white);
        border-radius: 2rem;
        color: var(--primary-gray-6);
      }
      &-hover {
        &:hover {
          background: rgba(255, 255, 255, 0.223);
          @extend .btn--shadow;
        }
      }
      &-active {
        color: var(--primary-white);
        background: var(--primary-green);
        box-sizing: border-box;
        box-shadow: 0.4rem 0 0.8rem rgba(65, 173, 73, 0.2);
        span {
          background: var(--secondary-green);
          color: var(--primary-white);
          font-weight: bold;
        }
      }
    }
  }
  .list {
    min-height: 10rem;
    width: 100%;
    overflow: auto;
    display: flex;
    height: 100%;
    flex-direction: column;

    &-header {
      @include btn-font-style(700);
      color: var(--primary-gray-2);
      background: var(--primary-gray);
      width: 100%;
      position: sticky;
      top: 0;
      z-index: 1;
      display: flex;
      padding: 0rem 3.2rem 1.6rem;
    }
    .id {
      width: 15%;
    }
    .act {
      width: 18%;
      display: flex;
      align-items: center;
      img {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50%;
        margin-right: 1rem;
        object-fit: cover;
      }
    }
    .email {
      width: 22%;
    }
    .phone {
      width: 15%;
    }
    .role {
      width: 15%;
    }
    .status {
      width: 15%;
    }
  }
}
