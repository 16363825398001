@import "../../../assets/styles/button";

.app-form-header {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--primary-green);
  border-bottom: 1px solid #bdc6d7;
  .title {
    margin: 1.5rem auto;
    font-family: SVN-Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 2.7rem;
    // text-transform: capitalize;
    color: var(--primary-white);
  }
  .sr-only {
    position: absolute;
    white-space: nowrap;
    width: 1px;
    height: 1px;
    overflow: hidden;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    margin: -1px;
  }
  .close {
    @extend .btn-cancel-style;
    background-color: transparent;
    color: var(--primary-white);
    padding: 1.2rem;
    margin-right: 1.5rem;
    &:hover {
      background-color: var(--secondary-green);
    }
  }
  .close-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
