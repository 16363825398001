@import "../../../assets/styles/button";
@import "../../../assets/styles/text";

.alarm {
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: SVN-Gotham;

  &-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }

  .content {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 4rem;
  }

  &__nav-header {
    display: flex;
    @include btn-font-style(500);
    color: var(--primary-darkgreen);
    align-items: center;
    cursor: pointer;
    .tab {
      margin-right: 2rem;
      padding: 0.9rem 1.5rem;
      display: flex;
      border-radius: 100rem;
      span {
        height: 2.4rem;
        padding: 0 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.8rem;
        font-size: 1.4rem;
        line-height: 2.2rem;
        background: var(--primary-white);
        border-radius: 2rem;
        color: var(--primary-gray-6);
      }
      &-hover {
        &:hover {
          background: rgba(255, 255, 255, 0.223);
          @extend .btn--shadow;
        }
      }
      &-active {
        color: var(--primary-white);
        background: var(--primary-green);
        box-sizing: border-box;
        box-shadow: 0.4rem 0 0.8rem rgba(65, 173, 73, 0.2);
        span {
          background: var(--secondary-green);
          color: var(--primary-white);
          font-weight: bold;
        }
      }
    }
    .inactive {
      color: #b8292f;
    }
  }
  .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .search-style {
      width: 40rem;
      margin-right: 1.5rem;
    }
  }
  .title {
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 4.4rem;
    color: #272b2f;
    padding: 3.3rem 0;
  }
  .list {
    min-height: 10rem;
    width: 100%;
    overflow: auto;
    display: flex;
    height: 100%;
    flex-direction: column;

    &-header {
      @include btn-font-style(700);
      color: var(--primary-gray-2);
      background: var(--primary-gray);
      width: 100%;
      position: sticky;
      top: 0;
      z-index: 1;
      display: flex;
      padding: 0rem 2rem 1.6rem;
    }
    .ic {
      width: 3%;
    }
    .id {
      width: 14%;
      @extend .text-overflow-1-lines;
      padding-right: 1.5rem;
    }
    .opc {
      width: 24%;
      @extend .text-overflow-1-lines;
      padding-right: 1.5rem;
    }
    .alarm-type {
      width: 12%;
      display: flex;
      flex-direction: column;
    }
    .condition-value {
      width: 28%;
      display: flex;
      flex-direction: column;
    }
    .alarm {
      width: 19%;
      display: flex;
      flex-direction: column;
    }

    .item-list-page {
      padding: 1.6rem 2rem;
    }
    .active {
      border-left: 0.6rem solid var(--primary-green);
    }
    .inactive {
      border-left: 0.6rem solid #b8292f;
    }
  }
}

.popover-menu-edit {
  button {
    display: flex;
    align-items: center;
    width: 15rem;
  }
}
