.profile-report {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: SVN-Gotham;
  position: relative;
  height: 100%;
  color: var(--primary-gray-3);

  .title {
    font-weight: bold;
    font-size: 3.2rem;
    line-height: 5.4rem;
    padding: 4rem 0;
  }
  .time {
    font-weight: 700;
    font-size: 4.8rem;
    line-height: 7.2rem;
  }
  button {
    border-radius: 3rem;
    margin: 6rem 2rem 4rem;
    width: 19.3rem;
  }
  .btn-cancel-style {
    border: 0.1rem solid var(--primary-green);
    color: var(--primary-green);
  }
  .form-text-field {
    width: 9.4rem;
    margin: 0 1.8rem;
    .MuiInputBase-root {
      height: 9.4rem;
      .input {
        font-size: 4.8rem;
      }
    }
  }
}
