@import "../../../../assets/styles/button";
@import "../../../../assets/styles/text";

.site-detail {
  font-family: SVN-Gotham;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--primary-gray);
  .content {
    padding: 4.4rem 3.8rem;
    overflow: auto;
    height: 100vh;
    color: var(--primary-gray-3);
    .box {
      background: var(--primary-white);
      padding: 4rem 5.7rem;
      @include btn-font-style();
      color: var(--primary-gray-4);
      border-radius: 0.4rem;
      box-shadow: 0px 4px 12px 0px #6f7d9029;
      overflow: hidden;
      .title {
        font-weight: 700;
        font-size: 2.4rem;
      }
      .label {
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 2rem;
        padding: 3.5rem 0 0.8rem;
        color: var(--primary-gray-2);
      }
      .MuiFormControlLabel-root {
        margin: 0;
        span {
          margin: 0;
        }
      }
      .address {
        @extend .text-overflow-2-lines;
        text-overflow: ellipsis;
        height: 4.8rem;
      }
    }
    .settings {
      &-tab {
        padding: 2rem 0;
        .tab {
          border: 0;
          background-color: #fff;
          color: #838283;
          margin-right: 7rem;
          padding: 1.2rem;
          outline: 0;
          font-weight: 450;
          font-size: 16px;
          line-height: 2.4rem;
          cursor: pointer;
          font-family: SVN-Gotham !important;
          &-active {
            color: #41ad49;
            padding-bottom: 1.2rem;
            border-bottom: 0.3rem solid #41ad49;
            font-weight: 700;
          }
        }
      }
      &-header {
        padding: 1.1rem 2.3rem;
        color: #525560;
      }
      &-item {
        background: #f7f7f7;
        padding: 1.1rem 2.3rem;
        color: #5a5959;
        margin-bottom: 0.8rem;
      }
    }
    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12rem;
        height: 12rem;
        border-radius: 50%;
        background: var(--primary-white);
        img {
          width: 9.6rem;
          height: 9.6rem;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .name {
        font-weight: 700;
        font-size: 2.8rem;
        line-height: 3.6rem;
        padding: 1.6rem 0 1.8rem;
      }
      .status {
        font-size: 1.6rem;
        padding-bottom: 3.8rem;
      }
      button {
        width: 24.2rem;
        height: 4.8rem;
        margin-bottom: 1.7rem;
      }
      .bord-red {
        border: 0.1rem solid #b8292f;
        color: #b8292f;
        background: var(--primary-white);
      }
    }
    .list {
      width: 100%;
      overflow: auto;
      display: flex;
      height: 100%;
      flex-direction: column;
      max-height: calc(100vh - 48rem);
      padding: 0 5.7rem 1rem;
      &-header {
        width: 100%;
        position: sticky;
        top: 0;
        z-index: 1;
        display: flex;
        flex-direction: column;
        background: var(--primary-white);
        padding-top: 4rem;
      }
    }
    .areas {
      &-header {
        @include btn-font-style(700);
        color: var(--primary-gray-2);
        padding: 1.2rem;
        width: 100%;
        position: sticky;
        top: 0;
        z-index: 1;
        display: flex;
      }
      &-item {
        background: #f8f8f8;
        border-radius: 0.4rem;
        padding: 1.2rem;
        margin-bottom: 0.8rem;
        color: var(--primary-gray-2);
        @include btn-font-style(450);
        cursor: pointer;
        .code {
          color: var(--secondary-green);
          text-decoration: underline;
        }
      }
    }
  }
}
