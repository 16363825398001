@font-face {
  font-family: "SVN-Gotham";
  src: url("../font/SVN-Gotham/SVN-Gotham-Regular.otf") format("truetype");
}
@font-face {
  font-family: "SVN-Gotham";
  font-weight: bold;
  src: url("../font/SVN-Gotham/SVN-Gotham-Bold.otf") format("truetype");
}

@font-face {
  font-family: "SVN-Gotham";
  font-style: italic;
  src: url("../font/SVN-Gotham/SVN-Gotham-Italic.otf") format("truetype");
}

@font-face {
  font-family: "SVN-Gotham";
  font-weight: bold;
  font-style: italic;
  src: url("../font/SVN-Gotham/SVN-Gotham-XLight-Italic.otf") format("truetype");
}
