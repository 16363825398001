@import "../../../../../assets/styles/button";
.area-device {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  &-form {
    height: 100%;
    width: 105rem;
    padding-top: 2.8rem;
    .list {
      overflow: auto;
      max-height: calc(100vh - 32rem);
      padding-right: 5rem;
    }
    .title {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin-bottom: 3.2rem;
    }
    .item {
      font-weight: 450;
      font-size: 1.6rem;
      line-height: 2.1rem;
      color: #838283;
      display: flex;
      align-items: center;
      margin-bottom: 1.6rem;
      position: relative;
      .MuiAutocomplete-fullWidth {
        width: 75%;
      }
    }
    .item-textfield {
      position: relative;
      display: inline-block;
      font-family: SVN-Gotham;
      width: 100%;

      > svg {
        position: absolute;
        top: 1.5rem;
        width: 2.2rem;
        height: 2.2rem;
        left: 0;
        margin: auto 1rem;
      }

      > .textfield__root {
        input {
          padding: 0 0 0 3.5rem !important;
          @include btn-font-style;
          color: #6c778d;
          font-size: 1.6rem;
        }
      }
      .MuiAutocomplete-clearIndicator {
        display: none;
        visibility: visible;
        position: absolute;
        right: -5rem;
        top: -0.2rem;
        .MuiSvgIcon-fontSizeSmall {
          font-size: 2.5rem !important;
        }
      }
    }
    .MuiAutocomplete-popupIndicator {
      display: none;
    }
    .MuiOutlinedInput-root {
      padding: 1.2rem 0 !important;
    }
  }
}

.area-modal-site-menu {
  font-family: SVN-Gotham;
  font-weight: 450;
  font-size: 1.6rem;
  line-height: 2.4rem;
  width: 100%;
  color: #070d14;
  &-item {
    cursor: pointer;
    display: flex;
    padding: 1.2rem 0;
  }
  .code {
    color: #838283;
  }
}

.noOptionsText {
  height: 35rem;
  overflow: auto;
  .area-modal-site-menu-hover {
    padding-bottom: 0.5rem;
    &:hover {
      background-color: var(--primary-gray-1);
    }
  }
  .area-modal-site-menu {
    &-item {
      padding: 1.5rem !important;
    }
  }
}

.MuiAutocomplete-noOptions {
  padding: 0 !important;
}

.dialog-create-site {
  padding: 4.5rem;
  .title {
    font-weight: 700;
    font-size: 3.6rem;
    line-height: 5.4rem;
    padding-bottom: 2.6rem;
  }
  .btn-cancel-style {
    border: 0.1rem solid var(--primary-green);
    border-radius: 3rem;
    color: var(--primary-green);
    width: 20rem;
    margin-right: 1.5rem;
  }
  .btn-confirm-style {
    width: 20rem;
    border-radius: 3rem;
    border-radius: 3rem;
    margin-left: 1.5rem;
  }
}
