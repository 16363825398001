.site-modal {
  background: var(--primary-white);
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: SVN-Gotham;
  .content {
    flex: 1;
    overflow: hidden auto;
    width: 100%;
    height: 100%;
    // padding: 6rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  &-form {
    // width: 80%;
    display: flex;
    // height: 100%;

    .title {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
    .label {
      font-weight: 450;
      font-size: 2rem;
      line-height: 3.1rem;
      display: flex;
      align-items: center;
      margin: 0.8rem 0 1.6rem;
      img {
        margin-right: 1rem;
        object-fit: contain;
      }
    }

    .area-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 0.1rem solid #838283;
      border-radius: 0.4rem;
      padding: 1rem 1.9rem;
      margin-bottom: 1.5rem;
      .name {
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2.5rem;
      }
    }
  }
}

.area-item-menu-list {
  font-size: 1.6rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.9rem;
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 0.1rem solid var(--primary-gray-1);
  &:last-child {
    border: 0;
  }
}
