.otp-form {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 5rem 0 5rem 0;
    .noti {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "SVN-Gotham";
      width: 38rem;
      height: 3.6rem;
      background-color: #fff7da;
      font-size: 1.4rem;
      border-radius: 0.4rem;
      color: #e89806;
      margin-top: 6rem;
    }
  }

  &__content {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    .title {
      font-family: "SVN-Gotham";
      font-size: 3.8rem;
      font-weight: 700;
      line-height: 5rem;
      text-align: center;
      color: var(--primary-green);
    }
    .description {
      font-family: "SVN-Gotham";
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 3.4rem;
      text-align: center;
      margin: 3rem 0;
    }
  }
  // &__form {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   .otpInput {
  //     width: 5.6rem;
  //     height: 7rem;
  //     border-radius: 0.5rem;
  //     margin-left: 1.5rem;
  //     text-align: center;
  //     font-size: 2rem;
  //   }
  // }
  &__wrong {
    font-family: "SVN-Gotham";
    font-size: 1.2rem;
    font-weight: 400;
    text-align: left;
    color: #e93c3c;
    width: 40rem;
  }
  .resend-inactive {
    font-family: "SVN-Gotham";
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
    padding-top: 6rem;
    opacity: 0.3;
    > a {
      cursor: not-allowed;
    }
  }
  &__resend {
    font-family: "SVN-Gotham";
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
    color: #0a6836;
    padding-top: 6rem;
  }
  &__submit {
    &-btn {
      width: 46.7rem;
      border-radius: 10rem;
      height: 5.6rem;
      background-color: #0a6836;
      margin: 4rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 1.8rem;
      color: #ffffff;
      border: 0.1rem solid #0a6836;
      cursor: pointer;
    }
  }
  &__text {
    font-family: "SVN-Gotham";
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
    margin-top: 16rem;
    margin-bottom: 5rem;
  }
  .img-right {
    width: 40rem;
  }
  .img-left {
    width: 40rem;
  }
}
input:focus-visible {
  outline: none;
}
