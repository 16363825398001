@import "../../../assets/styles/media";

.down-app {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  background: linear-gradient(180deg, #ffffff 44.27%, #d8e9d9 100%);
  height: 100vh;
  text-align: center;

  .container {
    position: relative;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: inherit;
    justify-content: center;
  }
  .img-lap {
    margin: 5rem 0 6.8rem;
    @include from576_ {
      // max-height: 26rem;
      max-width: 60rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .test {
    &-1 {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.6rem;
      background: linear-gradient(180deg, #0a6836 0%, #41ad49 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    &-2 {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.1rem;
      padding: 2.1rem 0 7.3rem;
      color: var(--primary-green);
    }
  }
  button {
    display: flex;
    z-index: 10;
    color: var(--primary-white);
    background: var(--primary-darkgreen);
    border: 0;
    padding: 0.9rem 1.5rem;
    border-radius: 0.6rem;
    align-items: center;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 15.5rem;
    margin: 0.8rem;
    text-align: start;
    span {
      font-size: 1.5rem;
      line-height: 1.6rem;
      font-weight: bold;
    }
    img {
      margin-right: 1.2rem;
    }
    &:hover {
      background-color: var(--secondary-green);
    }
  }
  .banner {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
