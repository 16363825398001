@import "../../../assets/styles/media";
@import "../../../assets/styles/button";

.app-bar {
  background: var(--primary-green) !important;
  box-shadow: none !important;
  .app-toolbar {
    justify-content: space-between;
    display: flex;
    padding-left: 0;
    &-left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      button {
        width: 72px;
        height: 100%;
        background-color: var(--primary-white);
        border-radius: 0;
        position: absolute;
        left: 2rem;
        top: 0;
        @include from1200_ {
          left: 1.7rem;
        }
        @include from1440_ {
          left: 1.5rem;
        }
        @include from1660_ {
          left: 1.2rem;
        }
      }
      .title {
        color: var(--primary-white);
        font-size: 2.8rem;
        font-weight: bold;
        line-height: 3.6rem;
        font-family: SVN-Gotham;
        padding-left: 16.4rem;
        @include from1200_ {
          padding-left: 14.8rem;
        }
        @include from1440_ {
          padding-left: 13.4rem;
        }
        @include from1660_ {
          padding-left: 11.5rem;
        }
      }
    }

    &-right {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .notification {
        margin-right: 1.5rem;
        cursor: pointer;
      }

      > svg {
        margin-right: 1.5rem;
        cursor: pointer;
      }
      .menu-user {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.4rem;
        width: 4.8rem;
        height: 4.8rem;
        img {
          cursor: pointer;
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          box-sizing: border-box;
          border: 0.1rem solid #d5d9e2;
          object-fit: cover;
        }
        &:hover {
          background-color: var(--secondary-green);
        }
      }
    }
  }
}

.DrawerHeader {
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  &_img {
    width: 8.3rem;
    height: 5rem;
    margin-right: 3.5rem;
    @include from1200_ {
      margin-right: 7rem;
    }
    @include from1440_ {
      margin-right: 5rem;
    }
    @include from1660_ {
      margin-right: 3.5rem;
    }
  }
}

.nav-list {
  .nav-item {
    display: flex;
    margin: 2rem 2.5rem;
    @include from1200_ {
      margin: 2rem;
    }
    @include from1440_ {
      margin: 2rem 1.5rem;
    }
    @include from1660_ {
      margin: 2rem 1rem;
    }

    .label {
      font-family: SVN-Gotham;
      align-self: center;
      font-size: 1.8rem;
      line-height: calc(1.8rem * 1.5);
      margin-left: 2.5rem;
      text-transform: capitalize;
      @include from1660_ {
        margin-left: 1.2rem;
      }
    }
    .btn-hover {
      &:hover {
        background: var(--primary-gray-1);
      }
    }
    &:hover {
      background: var(--primary-gray-1);
      border-radius: 0.5rem;
    }
  }
}

.layout__content__body {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
