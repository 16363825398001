@import "../../../assets/styles/button";

.report {
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: SVN-Gotham;

  &-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }

  .content {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 4rem;
  }

  &__nav-header {
    display: flex;
    @include btn-font-style(500);
    color: var(--primary-darkgreen);
    align-items: center;
    cursor: pointer;
    .tab {
      margin-right: 2rem;
      padding: 0.9rem 1.5rem;
      display: flex;
      border-radius: 100rem;
      span {
        height: 2.4rem;
        padding: 0 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.8rem;
        font-size: 1.4rem;
        line-height: 2.2rem;
        background: var(--primary-white);
        border-radius: 2rem;
        color: var(--primary-gray-6);
      }
      &-hover {
        &:hover {
          background: rgba(255, 255, 255, 0.223);
          @extend .btn--shadow;
        }
      }
      &-active {
        color: var(--primary-white);
        background: var(--primary-green);
        box-sizing: border-box;
        box-shadow: 0.4rem 0 0.8rem rgba(65, 173, 73, 0.2);
        span {
          background: var(--secondary-green);
          color: var(--primary-white);
          font-weight: bold;
        }
      }
    }
  }

  .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 4rem;
    .search-style {
      width: 40rem;
      margin-right: 1.5rem;
    }
  }
  .list {
    min-height: 10rem;
    width: 100%;
    overflow: auto;
    display: flex;
    height: 100%;
    flex-direction: column;

    &-header {
      @include btn-font-style(700);
      color: var(--primary-gray-2);
      background: var(--primary-gray);
      width: 100%;
      position: sticky;
      top: 0;
      z-index: 1;
      display: flex;
      padding: 0rem 3.2rem 1.6rem;
    }
    .site,
    .time {
      width: 40%;
      display: flex;
      align-items: center;
      img {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50%;
        margin-right: 1rem;
        object-fit: cover;
      }
      .code {
        font-weight: 450;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: var(--primary-gray-6);
      }
    }
    .report {
      width: 20%;
      align-items: center;
      justify-content: center;
    }
  }
}

.import-report {
  padding: 4rem 6rem;
  .title {
    font-weight: 700;
    font-size: 3.6rem;
    line-height: 4.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6rem;
  }
  .item-textfield {
    input {
      padding: 0 2rem !important;
    }
  }
  .form-item {
    margin-bottom: 0.1rem;
  }
  .MuiOutlinedInput-root {
    padding-right: 2rem !important;
  }
  .file-import {
    border: 0.1rem dashed var(--primary-gray-2);
    background: var(--primary-white);
    width: 100%;
    height: 24rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
    font-family: SVN-Gotham;
    color: var(--primary-gray-3);
    position: relative;
    margin-top: 4rem;
    outline: none;
    img {
      margin-bottom: 2.2rem;
      object-fit: contain;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1;
      cursor: pointer;
    }
    &:hover {
      .ic-click {
        display: flex;
      }
    }
    .ic-click {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      border-radius: 50%;
      padding: 1rem;
      border: 0;
      background-color: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      outline: none;
    }
  }
  .err {
    color: #b8292f;
    font-size: 1.2rem;
    font-family: SVN-Gotham !important;
  }
  .btn-confirm-style,
  .btn-cancel-style {
    height: 4.8rem;
  }
  .btn-cancel-style {
    border: 0.1rem solid #5a5959;
    margin-right: 3.2rem;
  }
}
