@import "../../../../assets/styles/button";

.account-detail {
  font-family: SVN-Gotham;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--primary-gray);
  .content {
    padding: 4.4rem 3.8rem;
    overflow: auto;
    height: 100vh;
    color: var(--primary-gray-3);
    .box {
      background: var(--primary-white);
      padding: 4rem 5.7rem;
      @include btn-font-style();
      color: var(--primary-gray-4);
      border-radius: 0.4rem;
      box-shadow: 0px 4px 12px 0px #6f7d9029;
      overflow: hidden;
      .title {
        font-weight: 700;
        font-size: 2.4rem;
      }
      .label {
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 2rem;
        padding: 3.5rem 0 0.8rem;
        color: var(--primary-gray-2);
      }
      .MuiFormControlLabel-root {
        margin: 0;
        span {
          margin: 0;
        }
      }
    }
    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12rem;
        height: 12rem;
        border-radius: 50%;
        background: var(--primary-white);
        img {
          width: 9.6rem;
          height: 9.6rem;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .name {
        font-weight: 700;
        font-size: 2.8rem;
        line-height: 3.6rem;
        padding: 1.6rem 0 1.8rem;
      }
      .status {
        font-size: 1.6rem;
        padding-bottom: 3.8rem;
      }
      button {
        width: 24.2rem;
        height: 4.8rem;
        margin-bottom: 1.7rem;
      }
      .bord-green {
        border: 0.1rem solid var(--primary-green);
        color: var(--primary-green);
        background: var(--primary-white);
      }
      .bord-red {
        border: 0.1rem solid #b8292f;
        color: #b8292f;
        background: var(--primary-white);
      }
    }
    .site-item {
      background: #f8f8f8;
      border-radius: 0.4rem;
      padding: 1.2rem;
      margin-bottom: 0.8rem;
      display: flex;
      align-items: center;
    }
  }
}
