@import "../../../../assets/styles/button";
.cus-reset {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: SVN-Gotham;
  position: relative;
  height: 100%;

  .title {
    font-weight: bold;
    font-size: 3.8rem;
    line-height: 3.2rem;
    color: var(--primary-green);
    padding: 6rem 0 2rem;
  }
  .des {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: var(--primary-gray-3);
    padding-bottom: 3.4rem;
  }
  .label {
    @include btn-font-style();
    color: var(--primary-gray-2);
    font-weight: 500;
  }

  .btn-confirm-style {
    font-size: 1.8rem;
    width: 46.7rem;
    margin: 3.6rem 0;
  }
  &-validate {
    font-size: 1.2rem;
    line-height: 1.7rem;
    font-weight: 400;
    color: var(--primary-gray-6);
    .validate-content {
      .item {
        display: flex;
        align-items: center;
        margin: 0.5rem 0;
        .ic {
          width: 1.2rem;
          height: 1.2rem;
          border-radius: 50%;
          background: rgba(42, 199, 105, 0.24);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
