$border-styles: search-border, search, text-border;
@each $border-style in $border-styles {
  .#{$border-style}-style {
    background: var(--primary-white);
    // height: 4.8rem;
    display: flex;
    justify-content: center;
    box-shadow: 0rem 1rem 3rem rgba(146, 151, 163, 0.2);
    width: 100%;
    border-radius: 0.4rem;
    .input {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-family: SVN-Gotham;
    }

    @if $border-style == search {
      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
    }

    @if $border-style == text-border {
      border: 1.2px solid #adacac;
      box-sizing: border-box;
      border-radius: 4rem;
      .MuiOutlinedInput-root {
        width: 100%;
        border-radius: 4rem;

        .input {
          font-family: "SVN-Gotham";
          font-size: 1.8rem;
        }
        &.Mui-focused fieldset {
          border-color: #0a6836 !important;
        }
      }

      .MuiFormHelperText-root {
        font-family: SVN-Gotham;
        font-size: 1.2rem;
        position: absolute;
        bottom: -2.5rem;
        margin-left: 0;

        &.Mui-error {
          color: #c6050e;
          font-size: 1.3rem;
        }
      }
    }
  }
}

.form-item {
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.form-label {
  font-family: SVN-Gotham;
  font-weight: bold;
  font-size: 1.4rem;
  color: #272b2f;
  margin-bottom: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__extra {
    color: #bdc6d7;
    font-weight: 500;
    font-size: 12px;
    margin-left: 8px;
  }
}

.form-text-field {
  width: 100%;
  .MuiInputBase-root {
    height: 4.8rem;
    .input {
      font-size: 1.6rem;
      padding: 0rem 1.6rem;
      font-family: SVN-Gotham !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
    }
    .MuiInputAdornment-root {
      .MuiTypography-root {
        font-family: SVN-Gotham !important;
        font-size: 1.8rem;
        color: var(--primary-gray-3);
      }
    }
  }

  .MuiFormHelperText-root {
    font-family: SVN-Gotham;
    font-size: 1.2rem;

    &.Mui-error {
      color: #c6050e;
    }
  }

  .MuiAutocomplete-input {
    font-family: SVN-Gotham;
    font-size: 1.6rem;
    padding-top: 0.2rem !important;
  }
}

.form-select-field {
  height: 4.8rem;
  font-size: 1.4rem !important;
  background-color: #ffffff;
  .MuiSelect-select {
    font-size: 1.6rem !important;
    font-family: SVN-Gotham;
    min-height: auto !important;
  }
  .form__placeholder {
    font-size: 1.6rem;
    color: #adacac;
    font-family: SVN-Gotham;
  }
}

.MuiFormHelperText-root {
  color: #f84948 !important;
  font-family: SVN-Gotham !important;
  font-size: 1rem !important;
  margin-left: 0 !important;
}
