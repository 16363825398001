.forgetpw-form {
  font-family: "SVN-Gotham";
  width: 100%;
  height: 100vh;
  position: relative;
  color: #ffffff;
  img {
    width: 100%;
    height: 100vh;
  }
  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 8%;
    left: 8%;
    height: 85%;
    &__logo {
      display: flex;
      padding-bottom: 10rem;
      img {
        width: 64.8rem;
        height: 14.3rem;
        object-fit: contain;
      }
    }
    .title {
      font-weight: 700;
      font-size: 3.8rem;
      line-height: 4.5rem;
      padding-bottom: 1.2rem;
    }
    .description {
      font-size: 2.2rem;
      font-weight: 400;
      line-height: 3.4rem;
      text-align: center;
      padding-bottom: 5rem;
    }
    &__form {
      width: 46.7rem;
      .btn-confirm-style {
        width: 100%;
        border-radius: 4rem;
        height: 5.6rem;
        margin: 4rem 0 2rem;
        cursor: pointer;
        background: var(--secondary-green);
      }
    }

    .text {
      font-size: 1.2rem;
      line-height: 2.4rem;
      font-weight: 400;
      text-align: center;
      margin-bottom: 5rem;
    }
  }
  &__label {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    text-align: left;
    padding-bottom: 1rem;
  }
  &__auth {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 3.5rem;
    text-align: center;
    padding-bottom: 8rem;
  }
}
