.floating-button-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: var(--background-gray-1);
  opacity: 0.85;
}
.floating-button {
  position: absolute;
  bottom: 3.2rem;
  right: 5.2rem;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  direction: rtl;
  &__item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
    transition: all 0.3s ease;
    &__list {
      width: 30rem;
      height: 10rem;
      box-shadow: 0px 4px 16px rgba(66, 78, 87, 0.16);
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease;
      margin-right: 10rem;
    }
    &__circle {
      width: 7.4rem;
      height: 7.4rem;
      border-radius: 50%;
      box-shadow: 0px 4px 16px rgba(66, 78, 87, 0.16);
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease;
      &.primary {
        background: var(--primary-green);
        &:active {
          box-shadow: 0 0 11px 1px var(--primary-green);
        }
        &.active {
          transform: rotate(45deg);
          background: #fff;
        }
      }
      &.red {
        background: #e53935;
        &:active {
          box-shadow: 0 0 11px 1px #e53935;
        }
      }
      &.purple {
        background: #6658f3;
        &:active {
          box-shadow: 0 0 11px 1px #6658f3;
        }
      }
    }
    &__text {
      margin-top: 1.2rem;
      font-size: 1.7rem;
      color: #2d3742;
      text-align: center;
      &.hide {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
}
