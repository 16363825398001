@import "../../../assets/styles/button";
@import "../../../assets/styles/text";

.area {
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: SVN-Gotham;

  &-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }
  .content {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 4rem;
  }
  .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 6rem;
    .title {
      font-weight: 700;
      font-size: 2.8rem;
      line-height: 3.6rem;
    }
    .search-style {
      width: 40rem;
      margin-right: 1.5rem;
    }
  }

  .list {
    min-height: 10rem;
    width: 100%;
    overflow: auto;
    display: flex;
    height: 100%;
    flex-direction: column;

    &-header {
      @include btn-font-style(700);
      color: var(--primary-gray-2);
      background: var(--primary-gray);
      width: 100%;
      position: sticky;
      top: 0;
      z-index: 1;
      display: flex;
      padding: 0rem 3.2rem 1.6rem;
    }
    .ic{
      width: 5%;
    }
    .area {
      width: 24%;
      display: flex;
      align-items: center;
      img {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50%;
        margin-right: 1rem;
        object-fit: cover;
      }
      .code {
        font-weight: 450;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: var(--primary-gray-6);
      }
    }
    .type {
      width: 21%;
    }
    .slot {
      width: 14%;
    }
    .device {
      width: 8%;
      text-align: end;
    }
    .pet {
      width: 13%;
      text-align: end;
      padding-right: 4rem;
    }
    .status {
      width: 15%;
    }
  }
}
