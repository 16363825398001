.area-modal {
  background: var(--primary-white);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: SVN-Gotham;
  .step-area {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.4rem 0;
    box-shadow: 0 0 10rem rgba(0, 0, 0, 0.1);
    width: 100%;
    .item {
      font-weight: 450;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: #838283;
      display: flex;
      justify-content: center;
      align-items: center;
      &-active {
        font-weight: 700;
        color: #41ad49;
      }
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
