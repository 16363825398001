@mixin label-default-font($weight: 400) {
  font-family: SVN-Gotham;
  font-style: normal;
  font-weight: $weight;
}

.btn-no-style {
  border: none;
  background: none;
  cursor: pointer;
}

$btn-styles: confirm, cancel;
@each $btn-style in $btn-styles {
  .btn-#{$btn-style}-style {
    @extend .btn-no-style;
    @include label-default-font(bold);
    font-size: 1.5rem;
    padding: 1.2rem 4.5rem;
    border-radius: 0.4rem;
    @if $btn-style == confirm {
      // background-color: var(--secondary-green);
      background-color: #0a6836;
      color: var(--primary-white);
    } @else {
      background-color: var(--primary-white);
      color: var(--primary-gray-4);
    }
  }
}

.app-check-box {
  color: var(--secondary-green) !important;
}

.app-switch-box {
  .Mui-checked {
    color: var(--secondary-green) !important;
  }
  .Mui-checked + .MuiSwitch-track {
    background-color: var(--secondary-green) !important;
  }
}
.form-autocomplete-select-field {
  font-size: 1.5rem !important;

  .MuiInputBase-root {
    height: 4.8rem;
    font-size: inherit;
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
    .MuiChip-root {
      padding: 0.4rem 1.2rem;
      margin: 0 3px;
      font-size: inherit;
      background: #f0f3f8;
      border-radius: 10rem;
      font-family: SVN-Gotham;
      font-style: normal;
      font-weight: 500;
      color: #272b2f;
    }

    .MuiInputBase-input {
      padding: 0 0.4rem;
    }
  }
}

.notification-icon-box {
  width: 10.8rem;
  height: 10.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &.green {
    background-color: #2ac769;
  }
  &.yellow {
    background-color: #ffd74b;
  }
  &.red {
    background-color: #f84948;
  }
  &.purple {
    background-color: #6658f3;
  }
}

@mixin truncate($maxWidth: 10rem) {
  display: block;
  width: $maxWidth;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin base-avatar($size: 5rem) {
  min-width: $size;
  max-width: $size;
  height: $size;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-white);
  border-radius: 50%;
  img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    border-radius: 50%;
  }
}

// button filter
.filter-style {
  width: 39.8rem;
  padding: 2.9rem 2.5rem;
  font-family: SVN-Gotham;
  position: relative;
  .title {
    font-weight: 700;
    font-size: 3.2rem;
    text-align: center;
  }
  .close-icon {
    position: absolute;
    top: 4rem;
    right: 3rem;
  }
  .lable {
    font-size: 1.8rem;
    font-weight: 700;
    padding: 4rem 0 1.9rem;
  }
  .MuiFormControlLabel-root {
    height: 4.6rem;
  }
  .MuiFormControlLabel-label {
    font-size: 1.6rem;
    font-family: SVN-Gotham;
    color: #312f30;
  }
  .btn-cancel-style {
    border: 0.1rem solid #838283;
    border-radius: 0.4rem;
  }
}

//item listing
.item-list-page {
  background: var(--primary-white);
  display: flex;
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: var(--primary-gray-3);
  width: 100%;
  padding: 1.6rem 3.2rem;
  margin-bottom: 0.8rem;
  align-items: center;
  cursor: pointer;
  border-radius: 0.4rem;
  &:hover {
    background: #f5fbf6;
    box-shadow: 0 2rem 3rem rgba(146, 151, 163, 0.2);
  }
}
