.area-setting {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  &-form {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 100%;
    width: 132rem;
    .title {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin-bottom: 3.2rem;
      text-align: start;
    }
    .label {
      font-weight: 450;
      font-size: 2rem;
      line-height: 3.1rem;
      display: flex;
      align-items: center;
      margin-bottom: 1.6rem;
      img {
        margin-right: 1rem;
        object-fit: contain;
      }
    }
  }
}
