@import "../../../assets/styles/button";

.profile {
  font-family: SVN-Gotham;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--primary-gray);
  .content {
    padding: 4.4rem 3.8rem;
    overflow: auto;
    height: 100vh;
    color: var(--primary-gray-3);
    .box {
      background: var(--primary-white);
      padding: 4rem 5.7rem;
      @include btn-font-style();
      color: var(--primary-gray-4);
      border-radius: 0.4rem;
      box-shadow: 0px 4px 12px 0px #6f7d9029;
      overflow: hidden;
      .title {
        font-weight: 700;
        font-size: 2.4rem;
      }
      .label {
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 2rem;
        padding: 3.5rem 0 0.8rem;
        color: var(--primary-gray-2);
      }
      .MuiFormControlLabel-root {
        margin: 0;
        span {
          margin: 0;
        }
      }
    }
    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12rem;
        height: 12rem;
        border-radius: 50%;
        background: var(--primary-white);
        position: relative;
        // img {
        //   width: 9.6rem;
        //   height: 9.6rem;
        //   border-radius: 50%;
        //   object-fit: cover;
        // }

        .image-upload {
          padding: 0;
          .image-upload__img,
          .image-upload__upload-btn {
            min-width: 9.6rem;
            max-width: 9.6rem;
            height: 9.6rem;
            object-fit: cover;
            margin: 0.8rem auto;
            filter: drop-shadow(0.4rem 0.4rem 2rem rgba(91, 91, 91, 0.5));
            outline: none;
          }
        }
        .ic-up-avt {
          position: absolute;
          background: var(--secondary-green);
          border: 0.2rem solid #d4d4d4;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.5rem;
          border-radius: 50%;
          right: -0.5rem;
          bottom: -2rem;
          cursor: pointer;
          width: 3.2rem;
          height: 3.2rem;
          outline: none;
        }
      }
      .name {
        font-weight: 700;
        font-size: 2.8rem;
        line-height: 3.6rem;
        padding: 1.6rem 0 1.8rem;
      }
      .status {
        font-size: 1.6rem;
        padding-bottom: 3.8rem;
      }
      button {
        width: 24.2rem;
        height: 4.8rem;
        margin-bottom: 1.7rem;
      }
      .bord-green {
        border: 0.1rem solid var(--primary-green);
        color: var(--primary-green);
        background: var(--primary-white);
      }
      .bord-red {
        border: 0.1rem solid #b8292f;
        color: #b8292f;
        background: var(--primary-white);
      }
    }
    .site {
      &-header {
        padding: 1.2rem;
        font-weight: bold;
        color: var(--primary-gray-2);
      }
      &-item {
        background: #f8f8f8;
        border-radius: 0.4rem;
        padding: 1.2rem;
        margin-bottom: 0.8rem;
        display: flex;
        align-items: center;
      }
    }
    .user-logins {
      background: transparent;
      box-shadow: none;
      margin: 0;
      .title {
        font-size: 2.4rem;
        font-weight: 700;
        font-style: "SVN-Gotham";
      }
      .label {
        font-weight: bold;
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: var(--primary-gray-2);
      }
      .MuiButtonBase-root {
        padding: 0;
        width: 100%;
        height: 6.9rem;
        min-height: 6.9rem;
        max-height: 6.9rem;
      }

      .MuiAccordionDetails-root {
        // padding: 0.5rem 6rem;
        padding: 0;
      }
    }
  }
}
