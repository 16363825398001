@import "../../../../assets/styles/button";

.left-report-filter {
  overflow: hidden;
  height: 100%;
  height: 100%;
  width: 100%;
  max-width: 35rem;
  background: var(--primary-white);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2.4rem 0;
  .title {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.8rem;
    margin-bottom: 2.6rem;
    width: 100%;
    padding-left: 3.6rem;
  }

  &-body {
    overflow: auto;
    width: 100%;
    height: 100%;
  }
  .item {
    cursor: pointer;
    width: 100%;
    padding: 1.2rem 3.6rem;
    display: flex;
    align-items: center;
    img {
      width: 3.2rem;
      height: 3.2rem;
      object-fit: cover;
      border-radius: 50%;
    }

    .name {
      @include btn-font-style();
      padding-left: 2rem;
    }
  }
  .active {
    background: var(--primary-gray-1);
    font-weight: 500;
  }
}
