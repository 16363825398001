.device-modal {
  background: var(--primary-white);
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: SVN-Gotham;
  .content {
    flex: 1;
    overflow: hidden auto;
    width: 100%;
    height: 100%;
    padding: 6rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  &-form {
    width: 90.3rem;
    .title {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3.2rem;
      padding: 6rem 0 3.8rem;
    }
  }
}
