@import "../../../../assets/styles/button";

.account-modal {
  background: var(--primary-white);
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: SVN-Gotham;
  .content {
    flex: 1;
    overflow: hidden auto;
    width: 100%;
    height: 100%;
    padding: 6rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  &-form {
    width: 90.3rem;
    .title {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3.2rem;
      padding: 6rem 0 3.8rem;
    }
    .status {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.4rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .email {
      display: flex;
      align-items: center;
      span {
        padding-left: 0;
      }
      &-text {
        font-weight: 450;
        font-size: 1.4rem;
        margin-left: 2rem;
      }
    }

    .site-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 0.1rem solid #838283;
      border-radius: 0.4rem;
      padding: 1rem 1.9rem;
      margin-bottom: 1.5rem;
      .name {
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2.5rem;
        .code {
          font-size: 1.4rem;
          color: #838283;
          font-weight: 400;
        }
        img {
          width: 4rem;
          height: 4rem;
          object-fit: content;
          border-radius: 50%;
          margin-right: 1.4rem;
        }
      }
    }
  }
  .footer-pending {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 1.6rem 6rem;
    background-color: var(--primary-white);
    box-shadow: 6px 5px 21px rgba(108, 72, 58, 0.2);
    input[type="submit"] {
      @extend .btn-confirm-style;
      @extend .btn--shiny;
      outline: none;
    }
    .btn-delete {
      background: var(--primary-white) !important;
      border: 1px solid #e53935 !important;
      color: #f84948 !important;
      margin-right: 1.6rem;
    }
  }
}

.site-item-menu-list {
  font-size: 1.6rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 1rem 1.9rem;
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 0.1rem solid var(--primary-gray-1);
  &:last-child {
    border: 0;
  }
  img {
    margin-right: 1.4rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    object-fit: cover;
  }
  .code {
    font-size: 1.4rem;
    color: #838283;
    font-weight: 400;
    font-family: SVN-Gotham;
  }
}
