.alarm-modal {
  background: var(--primary-white);
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: SVN-Gotham;
  .content {
    flex: 1;
    overflow: hidden auto;
    width: 100%;
    height: 100%;
    padding: 6rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  &-form {
    width: 125rem;
    .title {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3.2rem;
      padding: 2rem 0 3.8rem;
      &-site {
        font-size: 3.2rem;
      }
    }
    .MuiSelect-select {
      display: flex;
    }
  }
  .condition {
    display: flex;
    flex-direction: column;
    &-header {
      display: flex;
      margin-bottom: 2rem;
      font-size: 1.6rem;
      .label-condition {
        width: 25%;
      }
      .value {
        width: 25%;
        text-align: left;
      }
      .alarm-type {
        width: 25%;
        text-align: left;
      }
      .send-options {
        width: 25%;
        text-align: left;
      }
    }
    .form-select-field {
      width: 28rem;
    }
    .MuiFormControl-root {
      width: auto;
    }
    .MuiInputBase-root {
      padding-right: 0.8rem;
    }

    .condition-form {
      font-size: 1.6rem;
      font-family: SVN-Gotham;
      width: 100%;

      .content-condition {
        display: flex;
        align-items: center;
        background-color: var(--primary-gray-1);
        height: 9rem;
        margin: 0.5rem 0;
        .label-condition {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 4.8rem;
          color: #272b2f;
          margin-left: 1rem;
        }
        .cond {
          display: flex;
          font-family: "SVN-Gotham";
          width: 28rem;
          height: 4.8rem;
          border: 1.5px solid #adacac;
          border-radius: 4px;
          justify-content: space-around;
          align-items: center;
          background-color: var(--primary-white);
          &-err {
            border: 1px solid red;
          }
          &-none {
            justify-content: flex-start;
            .text {
              font-size: 1.6rem;
              color: #adacac;
              margin-left: 1rem;
            }
          }
          &-input {
            border: none;
            font-size: 1.6rem;
            width: 5.5rem;
            font-family: "SVN-Gotham";
            &:focus {
              outline: none;
            }
          }
          &-text {
            font-size: 1.6rem;
            width: 7rem;
            color: #41ad49;
          }
        }
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }
    input:disabled {
      background-color: var(--primary-white);
    }
  }
}
