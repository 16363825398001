.notification-comp {
  &__inner {
    padding: 9rem 5.3rem 6.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: SVN-Gotham;
    &__icon {
      .green {
        background: linear-gradient(180deg, #0a6836 0%, #41ad49 100%);
      }
      .red {
        background: linear-gradient(180deg, #680a0a 0%, #e86565 100%);
      }
    }
    &__title {
      margin-top: 4.1rem;
      font-weight: bold;
      text-align: center;
      font-size: 3.5rem;
      line-height: 122%;
      color: #171725;
    }
    &__sub-title {
      margin-top: 2.2rem;
      text-align: center;
      font-size: 1.5rem;
      line-height: 140%;
      color: #272b2f;
    }
    &__actions {
      width: 100%;
      margin-top: 4.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        border-radius: 3rem;
      }
    }
  }
  .close-icon {
    font-size: 3rem;
    cursor: pointer;
    margin: 2rem 2rem;
    position: absolute;
    right: 1.6 rem;
    top: 1.6 rem;
  }
}
