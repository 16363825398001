// https://www.figma.com/file/TK7L8DOv9RFSEGq5IQANby/Greenfeed-Design-System?node-id=0%3A1
@import "../styles/media";

:root {
  --primary-white: #fff;
  --primary-gray: #f0f3f8;
  --primary-green: #0a6836;
  --primary-darkgreen: #004125;
  --secondary-green: #41ad49;
  --secondary-darkgreen: #2a7a30;
  --dark-green: #3b865e;
  --primary-gray-1: #efefef;
  --primary-gray-2: #525560;
  --primary-gray-3: #312f30;
  --primary-gray-4: #272b2f;
  --primary-gray-5: #2d3742;
  --primary-gray-6: #5a5959;
  --primary-gray-7: #aeb8c7;
  --primary-gray-8: #7d8596;
  --color-dark: #161315;
  --color-dark-1: #070d14;
  --navbar-max-width: 100%;
}

*,
*::after,
*::before,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  width: 100%;
  font-size: 37.5%; // 6px
  @include from1200_ {
    font-size: 43.75%; // 7px
  }
  @include from1440_ {
    font-size: 50%; // 8px
  }
  @include from1660_ {
    font-size: 62.5%; // 10px
  }
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "SVN-Gotham", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

ul[role="list"],
ol[role="list"] {
  list-style-type: none;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

#notistack-snackbar {
  font-size: 1.2rem;
}

// scrollbar

::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
}
::-webkit-scrollbar-button {
  width: 0.6rem;
  height: 0;
}
::-webkit-scrollbar-thumb {
  background: var(--primary-gray-7);
  border: 0rem ridge var(--primary-gray-7);
  border-radius: 1.5rem;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-gray-8);
}
::-webkit-scrollbar-thumb:active {
  background: var(--primary-gray-8);
}
::-webkit-scrollbar-track {
  background: var(--primary-gray);
  border-radius: 0.2rem;
}
::-webkit-scrollbar-track:hover {
  background: var(--primary-gray);
}
::-webkit-scrollbar-corner {
  background: transparent;
}
