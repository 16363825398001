.area-info {
  background: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    .product-form-avatar {
      margin-bottom: 4.2rem;
    }
    .title {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin-bottom: 2rem;
    }
    .status {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.4rem;
      text-align: end;
      margin-bottom: 2rem;
    }
  }
}
