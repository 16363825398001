@import "../../../assets/styles/button";
@import "../../../assets/styles/text";

.site {
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: SVN-Gotham;

  &-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 4rem;
    height: 100%;
  }

  .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 6rem;
    .title {
      font-weight: 700;
      font-size: 2.8rem;
      line-height: 3.6rem;
    }
    .search-style {
      width: 40rem;
      margin-right: 1.5rem;
    }
  }

  .list {
    min-height: 10rem;
    width: 100%;
    overflow: auto;
    display: flex;
    height: 100%;
    flex-direction: column;

    &-header {
      @include btn-font-style(700);
      color: var(--primary-gray-2);
      background: var(--primary-gray);
      width: 100%;
      position: sticky;
      top: 0;
      z-index: 1;
      display: flex;
      padding: 0rem 3.2rem 1.6rem;
    }
    .ic {
      width: 4%;
    }
    .id {
      width: 10%;
    }
    .name {
      width: 20%;
      display: flex;
      align-items: center;
      img {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50%;
        margin-right: 1rem;
        object-fit: cover;
      }
    }
    .pet {
      width: 10%;
    }
    .status {
      width: 14%;
    }
    .address {
      width: 22%;
      span {
        @extend .text-overflow-2-lines;
      }
    }
    .size {
      width: 9%;
      text-align: end;
    }
    .quantity {
      width: 11%;
      text-align: end;
    }
  }
}
