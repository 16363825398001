@import "../../../assets/styles/button";

.item-textfield {
  position: relative;
  display: inline-block;
  font-family: SVN-Gotham;
  width: 100%;

  > svg {
    position: absolute;
    top: 1.5rem;
    width: 2.2rem;
    height: 2.2rem;
    left: 0;
    margin: auto 1rem;
  }

  > .textfield__root {
    input {
      padding: 0 3rem !important;
      @include btn-font-style;
      color: #6c778d;
      font-size: 1.6rem;
    }
    // .MuiOutlinedInput-root {
    //   width: 100%;
    //   fieldset {
    //     border-color: #adacac;
    //   }
    //   &.Mui-focused fieldset {
    //     border-color: var(--secondary-darkgreen) !important;
    //   }
    // }
  }

  .MuiOutlinedInput-root {
    padding: 1.2rem 0;
  }
}
