@import "../../../assets/styles/button";

.image-upload {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 2rem 1rem 1rem 2rem;
  font-family: SVN-Gotham;
  button {
    outline: none;
  }
  &__upload-btn {
    // @extend .btn-no-style;
    width: 13rem;
    height: 13rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1rem 0.8rem 0;
    background: #ffffff;
    border: 1px dashed #6c778d;
    border-radius: 50%;
    cursor: pointer;
  }

  &__input {
    position: relative;
    width: 8rem;
    height: 8rem;
    margin: 0 1rem 0.8rem 0;
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1;
      cursor: pointer;
    }
    label {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      background: #ffffff;
      border: 1px dashed #6c778d;
      border-radius: 0.4rem;
    }
  }

  &__img {
    position: relative;
    min-width: 13rem;
    max-width: 13rem;
    height: 13rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1rem 0.8rem 0;
    border: 1px solid #d9d9d9;
    border-radius: 50%;

    & img {
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
      border-radius: 50%;
      width: -webkit-fill-available;
    }

    &:hover {
      .image-upload__img__actions {
        border-radius: 50%;
        display: flex;
      }

      .image-upload__img__unselected {
        display: unset;
      }
    }

    &__actions {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: none;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 0.8rem;
      border-radius: 2px;
      .ic-click {
        background: rgba(255, 255, 255, 0.5);
        border: 0;
        padding: 1rem;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &__avt {
        top: 90%;
        left: -1.5rem;
        border-radius: 0.8rem !important;
        flex-direction: column;
        background-color: var(--primary-white);
        padding: 0;
        width: 19.2rem;
        button {
          background: transparent;
          border: 0;
          outline: 0;
          width: 19.2rem !important;
          @include btn-font-style(400);
          margin: 0 !important;
          border-radius: 0.8rem !important;
          text-align: start;
          padding: 0 1rem;
          &:hover {
            background: #f0f3f8;
          }
        }
      }
    }

    &__selected {
      // @extend .btn-no-style;
      position: absolute;
      top: -0.8rem;
      right: -1.8rem;
    }

    &__unselected {
      // @extend .btn-no-style;
      position: absolute;
      top: -0.8rem;
      right: -1.8rem;
      display: none;
    }
  }

  &__preview {
    &__avt {
      .app-form-header {
        .title {
          // margin: 5rem auto 0 !important;
          text-align: center !important;
          padding-left: 5.6rem;
        }
      }
    }
    .app-form-header {
      border-bottom: 0;
      .title {
        // margin: 5rem auto 0 4rem;
        text-transform: none;
        font-size: 3.6rem;
      }
    }
    &__body {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      button {
        outline: none;
      }
      &__upload {
        margin-top: 4rem;
        width: 27rem;
        height: 27rem;
        border: 1px dashed #525560;
        border-radius: 50%;
        &__avt {
          width: 85%;
          height: 40rem;
          border-radius: 0;
          margin-bottom: 3rem;
        }

        &__img {
          position: relative;
          min-width: 100%;
          max-width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          & img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            background-position: center;
            pointer-events: none;
            background-repeat: no-repeat;
          }

          &:hover {
            .image-upload__preview__body__upload__img__actions {
              display: flex;
              border-radius: 50%;
            }
          }

          &__actions {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: none;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 0.8rem;
            border-radius: 2px;

            .ic-click {
              background: rgba(255, 255, 255, 0.5);
              border: 0;
              padding: 1rem;
              border-radius: 50%;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
      .img-avt {
        width: 27.2rem !important;
        height: 27.2rem !important;
        border-radius: 50% !important;
        margin: 5rem auto;
        padding: 0;

        .image-upload__preview__body__upload__img__actions {
          border-radius: 50% !important;
        }
        .image-upload__preview__body__upload {
          padding: 0;
          &__img {
            border-radius: 50% !important;

            img {
              border-radius: 50%;
              min-width: 27.2rem !important;
              object-fit: cover;
            }
          }
        }
      }

      &__input {
        position: relative;
        width: 100%;
        height: 100%;

        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 1;
          cursor: pointer;
        }
        label {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &__alt {
        margin-top: 4rem;
        font-family: SVN-Gotham;
        font-style: normal;
        font-weight: bold;
        font-size: 1.3rem;
        color: #272b2f;
      }
    }
    .des {
      font-size: 2.4rem;
      line-height: 3.5rem;
      text-align: center;
      margin-top: 4rem;
      font-family: SVN-Gotham;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      align-items: center;

      .btn-cancel-style {
        color: var(--primary-green);
        border: 0.1rem solid var(--primary-green);
        width: fit-content;
        margin-top: 2.4rem;
      }

      &-suc {
        font-weight: bold;
        font-size: 2.4rem;
        line-height: 3.5rem;
        text-align: center;
        cursor: pointer;

        span {
          text-decoration: underline;
          font-weight: normal;
          font-size: 1.8rem;
          color: #525560;
          position: relative;
          cursor: pointer;

          input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 1;
            cursor: pointer;
          }
        }
      }
    }
    &__actions {
      padding: 0.8rem 4rem 4rem 4rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .btn-group {
      justify-content: space-between;
      padding-top: 6rem;
      .btn-remove-style,
      .btn-confirm-style {
        width: 23rem;
      }
      .hide {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}
