.empty {
  margin-top: 15rem;
  text-align: center;
  img {
    object-fit: contain;
  }
  .title {
    font-weight: bold;
    font-size: 3.6rem;
    line-height: 4.2rem;
    color: var(--primary-gray-6) !important;
    margin: 3rem 0;
  }
  .text {
    font-size: 2rem;
    line-height: 3.1rem;
    color: #838283;
  }
}
